@font-face {
  font-family: "Estedad";
  src: url("./assets//fonts//Estedad.ttf") format("ttf");
}

body {
  margin: 0;
  padding: 0px;
  box-sizing: border-box;
  width: 100%;
  height: 100vh;
  height: 100svh;
  overflow: scroll;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Targets the whole scrollbar */
::-webkit-scrollbar {
  width: 6px;
  /* width of the entire scrollbar */
  height: 12px;
  /* height of the scrollbar (for horizontal bars) */
}


/* Targets the draggable part of the scrollbar */
::-webkit-scrollbar-thumb {
  background-color: #3A002B;
  /* color of the scrollbar */
  border-radius: 6px;
  /* roundness of the scrollbar thumb */
  /* adds padding around thumb */
}

input::-ms-reveal,
input::-ms-clear {
  display: none;
}