.floating-label-input {
    position: relative;
}

.floating-label-input label {
    position: absolute;
    top: 18px;
    left: 24px;
    color: #aaa;
    transition: all 0.3s ease-out;
    pointer-events: none;
    font-size: 16px;
    font-family: 'Helvetica Neue', Arial, sans-serif;
}



.floating-label-input input {
    width: 100%;
    height: 60px;
    padding: 0 32px;
    border: 1px solid #ccc;
    border-radius: 16px;
    outline: none;
    font-size: 16px;
    text-align: left;
    color: #000;
    font-family: 'Helvetica Neue', Arial, sans-serif;
}

.floating-label-input input:focus+label,
.floating-label-input label.label-focused {
    top: -10px;
    left: 24px;
    font-size: 14px;
    color: #888888;
    background-color: #ffffff;
    padding: 0 8px;
    font-family: 'Helvetica Neue', Arial, sans-serif;
}


@media (max-width: 600px) {
    .floating-label-input input {
        height: 50px;
    }

    .floating-label-input label {
        top: 14px;
        font-size: 14px;
    }

    .floating-label-input input:focus+label,
    .floating-label-input label.label-focused {
        font-size: 12px;
    }
}