/* Container to cover the whole screen with a semi-transparent background */
.spinner-container {
    position: fixed;
    /* Fixed positioning to cover the whole viewport */
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.24);
    /* Semi-transparent background */
    display: flex;
    /* Center the spinner */
    align-items: center;
    justify-content: center;
    z-index: 9999;
    /* High z-index to ensure it’s on top of other elements */
}

/* Wrapper to style the spinner */
.spinner-wrapper {
    width: 100px;
    height: 100px;
    border-radius: 24px;
    background-color: #4C0039;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

/* Spinner animation and styling */
.spinner {
    width: 8px;
    height: 8px;
    border-radius: 8px;
    box-shadow: 20px 0px 0 0 rgba(255, 255, 255, 0.2), 16.2px 11.8px 0 0 rgba(255, 255, 255, 0.4), 6.2px 19px 0 0 rgba(255, 255, 255, 0.6), -6.2px 19px 0 0 rgba(255, 255, 255, 0.8), -16.2px 11.8px 0 0 #ffffff;
    animation: spinner-b87k6z 1s infinite linear;
}

@keyframes spinner-b87k6z {
    to {
        transform: rotate(360deg);
    }
}